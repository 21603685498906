<template>
  <div>
    <v-data-table
      :headers="tableDataHeaders"
      :items="filteredCampaigns"
      hide-default-footer
      :loading="loading"
      :items-per-page="10000"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-if="$vuetify.breakpoint.smAndUp" v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.unique_click">
            <td v-if="!isBusiness">
              <router-link
                v-if="!isInfluencer"
                :to="{
                  name: 'business',
                  params: { id: item.campaign.business.uuid }
                }"
              >
                {{ item.campaign.business.display_name }}
              </router-link>
              <span v-else>
                {{ item.campaign.business.display_name }}
              </span>
            </td>

            <td>
              <router-link :to="{ name: 'campaign', params: { id: item.campaign.uuid } }">
                {{ item.campaign.name }}
              </router-link>
            </td>
            <td class="text-right">{{ item.clicks | local_numbers }}</td>
            <td class="text-right">{{ item.unique_clicks | local_numbers }}</td>
            <td class="text-right">{{ item.sales | local_numbers }}</td>
            <td class="text-right">{{ item.conversion | local_numbers }}%</td>
            <td class="text-right">{{ item.value | local_numbers }}</td>
            <td class="text-right">{{ item.commission | local_numbers }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import { mapGetters } from "vuex";

export default {
  filters: {
    local_numbers
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters("core/auth", ["isInfluencer", "isBusiness"]),

    tableDataHeaders() {
      // Headers for business
      if (this.isBusiness) {
        return [
          { text: "Campaign", value: "campaign.name", sortable: true },
          { text: "Clicks", value: "clicks", sortable: true, align: "right" },
          { text: "Unique clicks", value: "value", sortable: true, align: "right" },
          { text: "Sales", value: "sales", sortable: true, align: "right" },
          { text: "Conversion rate", value: "conversion", sortable: true, align: "right" },
          { text: "Revenue", value: "value", sortable: true, align: "right" },
          { text: "Commission", value: "commission", sortable: true, align: "right" }
        ]
      }

      // Default headers
      return [
        { text: "Brand", value: "campaign.business.display_name", sortable: true },
        { text: "Campaign", value: "campaign.name", sortable: true },
        { text: "Click", value: "clicks", sortable: true, align: "right" },
        { text: "Unique clicks", value: "value", sortable: true, align: "right" },
        { text: "Sales", value: "sales", sortable: true, align: "right" },
        { text: "Conversion rate", value: "conversion", sortable: true, align: "right" },
        { text: "Revenue", value: "value", sortable: true, align: "right" },
        { text: "Commission", value: "commission", sortable: true, align: "right" },
      ];
    },
    filteredCampaigns() {
      return this.items.filter(item => item.campaign !== null);
    }
  }
};
</script>
