<template>
  <v-autocomplete
    v-model="selected"
    label="Business"
    :search-input.sync="search"
    :loading="loading"
    :items="filteredItems"
    item-text="full_name"
    item-value="uuid"
    :return-object="returnObject"
    :clearable="clearable"
    :error-messages="errorMessages"
    :solo="solo"
    :hide-details="hideDetails"
    item-color="accent"
    color="primary"
    @click:clear="handleOnSelectChange"
  >
    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>
    <!-- Selected item -->
    <template slot="selection" slot-scope="prop">
      {{ prop.item.display_name }}
    </template>
    <template slot="item" slot-scope="prop">
      <v-list-item-content>
        <v-list-item-title>{{ prop.item.display_name }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import _ from "lodash";
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true
    },
    errorMessages: {
      required: false,
      default: null
    },
    solo: {
      required: false,
      default: false
    },
    hideDetails: {
      required: false,
      default: false
    }
  },
  data: () => ({
    items: [],
    search: "",
    loading: true,
    cleared: false
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    filteredItems() {
      return this.items.map(item => {
        // Full name is used for the search
        item.full_name = item.display_name + " " + item.company;
        return item;
      });
    }
  },
  methods: {
    ...mapActions("core", ["getBusinesses"]),
    handleOnSelectChange() {
      this.cleared = true
    },
    getItems() {
      this.loading = true;
      let params = {
        search: this.search,
        limit: 100,
        page: 1
      };
      this.getBusinesses(params).then(
        businesses => {
          this.items = businesses.response;
          this.loading = false;
          
        },
        error => {
          console.log(error);
          this.loading = false;
        }
      );
    }
  },
  watch: {
    search: {
      handler: _.debounce(function() {
        this.getItems();
      }, 500)
    }
  },
  created() {
    this.getItems();
  }
};
</script>
